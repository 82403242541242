import axios from 'axios'

/**
 * Finds the row in the array where the given field matches the given value, then returns that object
 * @param {String|Number|Object} value
 * @param Array array
 * @param String field
 */
export function getInArray (value, array, field) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === value) {
      return { index: i, value: array[i] }
    } else if (typeof (array[i]) === 'object') {
      if (field == null) {
        if (JSON.stringify(value) === JSON.stringify(array[i])) return { index: i, value: array[i] }
      } else if (field in array[i]) {
        if (typeof (value) === 'object' && field in value && array[i][field] === value[field]) {
          return { index: i, value: array[i] }
        } else if (typeof (value) === 'object' && array[i][field] === value) {
          return { index: i, value: array[i] }
        } else if (typeof (value) === 'string' && array[i][field] === value) {
          return { index: i, value: array[i] }
        }
      }
    }
  }
  return false
}

/**
 * Determines if the given value is in the array, or if a given field within the array has the given value
 * @param {String|Number|Object} value
 * @param Array array
 * @param String field
 */
export function inArray (value, array, field) {
  return !!getInArray(value, array, field)
}

export async function uploadFile ({ file, filename, folder, extraFormData }, service, authenticated, onUploadProgress) {
  if (!service) service = '/service/drive-upload'
  else if (service.substring(0, 1) !== '/') service = '/' + service
  let formData = new FormData()
  formData.append('filename', filename)
  formData.append('file', file)
  formData.append('contentType', file.type)
  formData.append('folder', folder)
  if (extraFormData && typeof (extraFormData) === 'object') {
    console.log(extraFormData)
    for (const l in extraFormData) {
      console.log('Adding field ' + l + ' from extraFormData: ' + extraFormData[l])
      formData.append(l, extraFormData[l])
    }
  }
  const headers = {}
  if (authenticated) {
    let token = window.localStorage['feathers-jwt']
    headers['Authorization'] = `Bearer ${token}`
  }
  try {
    const baseUrl = (process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH).replace('/socket.io', '')
    // Add a slash between the /api and the service if there is not already one on the service
    if (service.substring(0, 1) !== '/') service = '/' + service
    let res = await axios.post(baseUrl + service, formData, { headers, onUploadProgress })
    return res.data.id
  } catch (e) {
    console.log(e)
    return false
  }
}

export function getWeekOfYear (date) {
  const dt = new Date(date)
  const yearStart = new Date(dt.getFullYear(), 0, 1)
  return Math.ceil((((dt.getTime() - yearStart.getTime()) / 86400000) + yearStart.getDay() + 1) / 7)
};

export async function sleep (ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => { resolve() }, ms)
  })
};

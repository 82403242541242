<template>
  <v-card style="margin-bottom:5em">
    <v-toolbar>
      <v-toolbar-title>Academic Program/Advisor Change Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="loadData()">
            <v-icon>fal fa-sync</v-icon>
          </v-btn>
        </template>
        <span>Refresh the data</span>
      </v-tooltip>
      <v-text-field ref="searchField" v-model="searchText" label="Search (Name/Banner ID)" style="max-width:260px" prepend-inner-icon="fal fa-search" clear-icon="fal fa-times" hide-details clearable outlined dense @blur="searchBlur"></v-text-field>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col :cols="12" md="9">
          <v-select v-model="filter" :items="filterOptions" label="Status Filter" outlined multiple chips small-chips hide-details>
            <template v-slot:prepend-inner>
              <v-icon>fal fa-filter</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col :cols="12" md="3">
          <v-select v-model="term" :items="terms" label="Term" outlined></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="students" :headers="headers" :server-items-length="totalCount" :options="options" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" @update:options="updateOptions" @dblclick:row="(x, { item: { _id } }) => $router.push('/student/program-change/admin/' + _id)">
      <template v-slot:item.createdAt="{ item }">{{ stringFormatDate(item.createdAt) }}</template>
      <template v-slot:item.action="{ item }">
        <v-btn icon :to="'/student/program-change/admin/' + item._id">
          <v-icon>fal fa-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="isRecords">{{ filterOptions.reduce((prev, { value, text}) => value === item.status ? text : prev, item.status) }}</span>
        <span v-else>{{ item.advisorApproval.filter(({ pidm }) => pidm === user.pidm)[0].approved ? 'Approved' : 'Pending' }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../../helpers/formatters'
import { inArray } from '../../../../helpers/functions'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const isRecords = computed(() => 'Records' in roles.value || 'Technology Services' in roles.value)
    const term = computed({
      get: () => root.$store.state.student.programAdmin.term,
      set: (term) => root.$store.commit('student/setProgramAdminFields', { term })
    })
    const terms = ref([])
    const students = ref([])
    const totalCount = ref(0)
    const headers = ref([
      { text: 'Term', value: 'term' },
      { text: 'Banner ID', value: 'bannerId' },
      { text: 'Name', value: 'name' },
      { text: 'Submitted Date', value: 'createdAt' },
      { text: 'Status', value: 'status', sort: false },
      { text: 'View', value: 'action', sort: false }
    ])

    const searchActive = ref(false)
    const searchField = ref(null)
    const searchText = computed({
      get: () => root.$store.state.student.programAdmin.searchText || '',
      set: (val) => root.$store.commit('student/setProgramAdminFields', { searchText: val || '' })
    })

    function activateSearch () {
      searchActive.value = true
      root.$nextTick(() => searchField.value.focus())
    }

    function searchBlur () {
      if (searchText.value === '' || searchText.value == null) {
        searchActive.value = false
      }
    }

    const filter = computed({
      get: () => root.$store.state.student.programAdmin.filter,
      set: (val) => root.$store.commit('student/setProgramAdminFields', { filter: val })
    })
    const filterOptions = computed(() => {
      if (isRecords.value) {
        return [
          { value: 'Submitted', text: 'Pending Advisor Review by Records' },
          { value: 'Pending', text: 'Pending Advisor Approval' },
          { value: 'Approved', text: 'Approved, Ready to Process' },
          { value: 'Complete', text: 'Completed Submissions' },
          { value: 'Returned', text: 'Returned to Student' }
        ]
      } else {
        return [
          { value: false, text: 'Pending Approval' },
          { value: true, text: 'Approved' }
        ]
      }
    })

    const options = computed(() => root.$store.state.student.programAdmin.options || { page: 1, itemsPerPage: 10, sortBy: ['createdAt'], sortDesc: [1] })
    function updateOptions (opt) {
      root.$store.commit('student/setProgramAdminFields', { options: opt })
      loadData()
    }
    watch([searchText, filter], () => {
      loadData()
    })
    function loadData () {
      const query = {
        term: term.value,
        $sort: {}
      }
      if (!isRecords.value) {
        const $elemMatch = { pidm: user.value.pidm }
        if (filter.value.length > 0) {
          $elemMatch.approved = { $in: filter.value }
        }
        query.advisorApproval = { $elemMatch }
      } else if (filter.value.length > 0) {
        query.status = { $in: filter.value }
      }
      if (searchText.value !== '') {
        if (searchText.value.substring(0, 1) === '@') query.bannerId = { $regex: searchText.value }
        else query.name = { $regex: searchText.value, $options: 'i' }
      }
      const { page, itemsPerPage, sortBy, sortDesc } = options.value
      query.$limit = itemsPerPage
      query.$skip = (page - 1) * itemsPerPage
      for (let i = 0; i < sortBy.length; i++) {
        query.$sort[sortBy[i]] = sortDesc[i] ? 1 : -1
      }
      root.$feathers.service('student/major-change').find({ query }).then(({ data, total }) => {
        totalCount.value = total
        students.value = data
      })
    }

    watch(() => term, () => {
      if (term.value !== '' && term.value != null) loadData()
    })

    onMounted(() => {
      if (!isRecords.value && filter.value.length === 0) {
        filter.value.push(false)
      }
      root.$feathers.service('student/major-change').find({ query: { $distinct: 'term' } }).then(({ data }) => {
        terms.value = data.map(({ _id }) => _id).sort().reverse()
        term.value = terms.value[0]
        loadData()
      })
    })

    return {
      user,
      roles,
      isRecords,
      term,
      terms,
      students,
      totalCount,
      headers,
      searchActive,
      searchField,
      searchText,
      activateSearch,
      searchBlur,
      filter,
      filterOptions,
      options,
      updateOptions,
      loadData,
      stringFormatDate,
      inArray
    }
  }
}
</script>

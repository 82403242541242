var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"margin-bottom":"5em"}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Academic Program/Advisor Change Admin")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.loadData()}}},on),[_c('v-icon',[_vm._v("fal fa-sync")])],1)]}}])},[_c('span',[_vm._v("Refresh the data")])]),_c('v-text-field',{ref:"searchField",staticStyle:{"max-width":"260px"},attrs:{"label":"Search (Name/Banner ID)","prepend-inner-icon":"fal fa-search","clear-icon":"fal fa-times","hide-details":"","clearable":"","outlined":"","dense":""},on:{"blur":_vm.searchBlur},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"9"}},[_c('v-select',{attrs:{"items":_vm.filterOptions,"label":"Status Filter","outlined":"","multiple":"","chips":"","small-chips":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',[_vm._v("fal fa-filter")])]},proxy:true}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":12,"md":"3"}},[_c('v-select',{attrs:{"items":_vm.terms,"label":"Term","outlined":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.students,"headers":_vm.headers,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20, 25] }},on:{"update:options":_vm.updateOptions,"dblclick:row":function (x, ref) {
	var _id = ref.item._id;

	return _vm.$router.push('/student/program-change/admin/' + _id);
}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.createdAt)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":'/student/program-change/admin/' + item._id}},[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.isRecords)?_c('span',[_vm._v(_vm._s(_vm.filterOptions.reduce(function (prev, ref) {
	var value = ref.value;
	var text = ref.text;

	return value === item.status ? text : prev;
}, item.status)))]):_c('span',[_vm._v(_vm._s(item.advisorApproval.filter(function (ref) {
	var pidm = ref.pidm;

	return pidm === _vm.user.pidm;
})[0].approved ? 'Approved' : 'Pending'))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }